import type { IntrinsicElementWrapperComponent } from "@cometjs/react-utils";
import React from "react";

import { ReactComponent as PlayButtonSvg } from "./playButton/button.svg";

type PlayButtonComponent = IntrinsicElementWrapperComponent<"button">;

const PlayButton: PlayButtonComponent = ({ ...props }, forwardedRef) => {
  return (
    <button
      ref={forwardedRef}
      css={{
        width: "100%",
        height: "100%",
        background: "none",
        border: "none",
        cursor: "pointer",
      }}
      {...props}
    >
      <PlayButtonSvg />
    </button>
  );
};

export default React.forwardRef(PlayButton);
