import * as React from "react";
import { rem } from "polished";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";

import _Link from "gatsby-theme-cookierun-kingdom/src/components/Link";
import type { LinkType } from "gatsby-theme-cookierun-kingdom/src/link";

import { ReactComponent as ScrollButtonSvg } from "./scrollButton/button.svg";
import { ReactComponent as LineSvg } from "./scrollButton/line.svg";

type ScrollButtonProps = {
  to: LinkType;
  onClick: React.MouseEventHandler<HTMLElement>;
};

const Container = styled.div((props) => ({
  display: "none",
  textAlign: "center",
  [props.theme.media["lg"]]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Link = styled(_Link)({
  width: rem(59),
  height: "auto",
  background: "none",
  border: "none",
});

const Line = styled(LineSvg)({
  width: rem(1),
  marginTop: rem(5),
  height: "auto",
});

const ScrollButton: React.FC<ScrollButtonProps> = ({ to, onClick }) => {
  return (
    <Container>
      <Link link={to} onClick={onClick}>
        <ScrollButtonSvg />
      </Link>
      <Line />
    </Container>
  );
};

export default ScrollButton;
