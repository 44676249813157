import React from "react";
import ReactModal from "react-modal";
import { css, cx } from "@emotion/css";
import { useScrollLock } from "gatsby-theme-cookierun-kingdom/src/browsing";
import CloseButton from "~/src/components/CloseButton";

type ModalProps = {
  isVideoPlayerOpen: boolean;
  isNoticePopupOpen: boolean;
  handleClose: () => void;
};

const Modal: React.FC<ModalProps> = ({
  children,
  isVideoPlayerOpen,
  isNoticePopupOpen,
  handleClose,
}) => {
  useScrollLock(isVideoPlayerOpen || isNoticePopupOpen);
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isVideoPlayerOpen || isNoticePopupOpen}
      overlayClassName={cx(
        css({
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(3px) brightness(0.5)",
          zIndex: 1,
        })
      )}
      className={cx(
        css({
          position: "relative",
          top: "initial",
          left: "initial",
          right: "initial",
          bottom: "initial",
          border: "initial",
          background: "initial",
          overflow: "initial",
          borderRadius: "initial",
          outline: "initial",
          padding: "initial",
        })
      )}
    >
      <CloseButton
        onClick={handleClose}
        className={cx(isVideoPlayerOpen && "video-player")}
      />
      {children}
    </ReactModal>
  );
};

export default Modal;
