import * as React from "react";
import { rem } from "polished";
import { useInView } from "react-intersection-observer";
import { motion, useReducedMotion } from "framer-motion";
import { graphql } from "gatsby";
import { keyframes } from "@emotion/css";
import Img from "gatsby-image";

import {
  styled,
  useTheme,
} from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import BackgroundImage from "gatsby-theme-cookierun-kingdom/src/components/BackgroundImage";
import SectionTitle from "~/src/components/SectionTitle";
import SectionDeco from "gatsby-theme-cookierun-kingdom/src/components/SectionDeco";

type GameStorySectionProps = {
  sectionTitleMainTitle: string;
  sectionTitlePreTitle: string;
  storyText: string;
  gameStorybackgroundImage: GatsbyTypes.GameStorySection_gameStoryBackgroundImageFragment;
  yellowCookieImage: GatsbyTypes.GameStorySection_yellowCookieImageFragment;
  purpleCookieImage: GatsbyTypes.GameStorySection_purpleCookieImageFragment;
  pinkCookieImage: GatsbyTypes.GameStorySection_pinkCookieImageFragment;
  whiteCookieImage: GatsbyTypes.GameStorySection_whiteCookieImageFragment;
  orangeCookieImage: GatsbyTypes.GameStorySection_orangeCookieImageFragment;
  frontCloudImage: GatsbyTypes.GameStorySection_frontCloudImageFragment;
  backCloudImage: GatsbyTypes.GameStorySection_backCloudImageFragment;
};

const Container = styled.div((props) => ({
  textAlign: "center",
  backgroundColor: "#201A15",
  position: "relative",
  overflow: "hidden",
}));

const TransitionGroup = styled(motion.div)({
  position: "absolute",
});

const SectionTitleWrapper = styled.div((props) => ({
  padding: `${rem(34)} 0 ${rem(20)} 0`,
  color: "#dbbf91",
  display: "inline-block",
  position: "relative",
  [props.theme.media["md"]]: {
    padding: `${rem(78)} 0 ${rem(38)} 0`,
  },
}));

const StoryText = styled.p((props) => ({
  whiteSpace: "pre-line",
  fontSize: rem(15),
  lineHeight: 1.5,
  letterSpacing: "-0.05em",
  color: "#C4A893",
  margin: 0,
  marginBottom: rem(-10),
  [props.theme.media["md"]]: {
    fontSize: rem(18),
  },
  [props.theme.media["lg"]]: {
    marginBottom: rem(-80),
  },
}));

const BackCloudPosition = styled.div({});

const BackCloud = styled(BackgroundImage)((props) => ({
  height: rem(278),
  backgroundPosition: "center top",
  backgroundSize: "cover",
  [props.theme.media["md"]]: {
    height: rem(556),
  },
}));

const FrontCloud = styled(BackgroundImage)((props) => ({
  position: "absolute",
  bottom: rem(-5),
  backgroundPosition: "center top",
  backgroundSize: "cover",
  width: "100%",
  height: rem(242),
  [props.theme.media["md"]]: {
    height: rem(485),
    bottom: 0,
  },
}));

const Characters = styled.div((props) => ({
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
  width: rem(415),
  height: rem(200),
  marginBottom: rem(46),
  pointerEvents: "none",
  [props.theme.media["md"]]: {
    width: rem(1300),
    height: rem(710),
    marginBottom: rem(132),
  },
  [props.theme.media["lg"]]: {
    marginBottom: rem(60),
  },
}));

const characterMoving = keyframes`
  0% {
    transform: translateY(2%);
  }

  30% {
    transform: translateY(-2%);
  }

  100% {
    transform: translateY(2%);
  }
`;

const CharacterWrapper = styled.div((props) => ({
  position: "absolute",
  animation: `${characterMoving} 5s ease-in-out infinite`,
  [props.theme.reducedMotion]: {
    animation: "none",
  },
}));

const YellowCookieWrapper = styled(CharacterWrapper)((props) => ({
  width: rem(207),
  height: rem(200),
  left: rem(-15),
  top: 0,
  [props.theme.media["md"]]: {
    width: rem(415),
    height: rem(400),
  },
}));

const PurpleCookieWrapper = styled(CharacterWrapper)((props) => ({
  width: rem(136),
  height: rem(157),
  left: rem(-10),
  bottom: 0,
  animationDelay: "0.5s",
  [props.theme.media["md"]]: {
    width: rem(272),
    height: rem(314),
  },
}));

const PinkCookieWrapper = styled(CharacterWrapper)((props) => ({
  width: rem(139),
  height: rem(130),
  right: rem(35),
  bottom: rem(9),
  animationDelay: "0.5s",
  [props.theme.media["md"]]: {
    width: rem(278),
    height: rem(260),
    bottom: rem(18),
  },
}));

const WhiteCookieWrapper = styled(CharacterWrapper)((props) => ({
  width: rem(157),
  height: rem(149),
  left: 0,
  bottom: 0,
  animationDelay: "1s",
  [props.theme.media["md"]]: {
    width: rem(314),
    height: rem(298),
  },
}));

const OrangeCookieWrapper = styled(CharacterWrapper)((props) => ({
  width: rem(150),
  height: rem(120),
  top: rem(-30),
  right: rem(140),
  animationDelay: "1s",
  [props.theme.media["md"]]: {
    width: rem(300),
    height: rem(240),
    top: rem(50),
    right: rem(170),
  },
  [props.theme.media["lg"]]: {
    width: rem(374),
    height: rem(300),
    top: 0,
    right: 0,
  },
}));

const MainButtonWrapper = styled.div({});

const GameStorySection: React.FC<GameStorySectionProps> = ({
  sectionTitleMainTitle,
  sectionTitlePreTitle,
  storyText,
  gameStorybackgroundImage,
  yellowCookieImage,
  purpleCookieImage,
  pinkCookieImage,
  whiteCookieImage,
  orangeCookieImage,
  frontCloudImage,
  backCloudImage,
}) => {
  const shouldReduceMotion = useReducedMotion();

  const [transitionGroupRef, transitionGroupInView] = useInView();
  const [transitionGroupMounted, transitionGroupMount] = React.useReducer(
    () => true,
    false
  );
  React.useEffect(() => {
    if (transitionGroupInView && !transitionGroupMounted) {
      transitionGroupMount();
    }
  }, [transitionGroupInView, transitionGroupMounted]);
  const { media } = useTheme();

  return (
    <Container ref={transitionGroupRef}>
      {gameStorybackgroundImage.fluid && (
        <BackgroundImage fluid={gameStorybackgroundImage.fluid}>
          <SectionDeco decoColor="#C08D55" />
          <SectionTitleWrapper>
            <SectionTitle
              mainTitle={sectionTitleMainTitle}
              preTitle={sectionTitlePreTitle}
            />
          </SectionTitleWrapper>
          {(transitionGroupMounted || shouldReduceMotion) && (
            <>
              <TransitionGroup
                animate={
                  shouldReduceMotion
                    ? {}
                    : {
                        y: ["10%", "0%"],
                        opacity: [0, 1],
                      }
                }
                transition={{
                  duration: 1.5,
                }}
                css={{
                  position: "relative",
                  width: "100%",
                  height: "auto",
                  zIndex: 1,
                  opacity: shouldReduceMotion ? 1 : 0,
                }}
              >
                <StoryText>{storyText}</StoryText>
              </TransitionGroup>
              <BackCloudPosition />
              {backCloudImage.fluid && (
                <TransitionGroup
                  animate={
                    shouldReduceMotion
                      ? {}
                      : {
                          y: ["20%", "0%"],
                          opacity: [0, 1],
                        }
                  }
                  transition={{
                    duration: 1,
                    delay: 0.3,
                  }}
                  css={{
                    position: "relative",
                    opacity: shouldReduceMotion ? 1 : 0,
                  }}
                >
                  <BackCloud fluid={backCloudImage.fluid} />
                </TransitionGroup>
              )}
              <Characters>
                <TransitionGroup
                  animate={
                    shouldReduceMotion
                      ? {}
                      : {
                          y: ["50%", "0%"],
                          opacity: [0, 1],
                        }
                  }
                  transition={{
                    duration: 0.6,
                    delay: 2,
                  }}
                  css={{
                    width: rem(571),
                    height: rem(149),
                    top: 0,
                    left: 0,
                    opacity: shouldReduceMotion ? 1 : 0,
                    [media["md"]]: {
                      width: rem(1142),
                      height: rem(631),
                      top: 0,
                      left: "initial",
                      right: 0,
                    },
                  }}
                >
                  {whiteCookieImage.fluid && (
                    <WhiteCookieWrapper>
                      <Img fluid={whiteCookieImage.fluid} />
                    </WhiteCookieWrapper>
                  )}
                  {orangeCookieImage.fluid && (
                    <OrangeCookieWrapper>
                      <Img fluid={orangeCookieImage.fluid} />
                    </OrangeCookieWrapper>
                  )}
                </TransitionGroup>
                <TransitionGroup
                  animate={
                    shouldReduceMotion
                      ? {}
                      : {
                          y: ["50%", "0%"],
                          opacity: [0, 1],
                        }
                  }
                  transition={{
                    duration: 0.6,
                    delay: 1.5,
                  }}
                  css={{
                    width: rem(329),
                    height: rem(157),
                    top: rem(27),
                    left: rem(87),
                    opacity: shouldReduceMotion ? 1 : 0,
                    [media["md"]]: {
                      width: rem(718),
                      height: rem(314),
                      top: rem(365),
                      left: rem(350),
                    },
                  }}
                >
                  {purpleCookieImage.fluid && (
                    <PurpleCookieWrapper>
                      <Img fluid={purpleCookieImage.fluid} />
                    </PurpleCookieWrapper>
                  )}
                  {pinkCookieImage.fluid && (
                    <PinkCookieWrapper>
                      <Img fluid={pinkCookieImage.fluid} />
                    </PinkCookieWrapper>
                  )}
                </TransitionGroup>
                <TransitionGroup
                  animate={
                    shouldReduceMotion
                      ? {}
                      : {
                          y: ["50%", "0%"],
                          opacity: [0, 1],
                        }
                  }
                  transition={{
                    duration: 0.6,
                    delay: 1,
                  }}
                  css={{
                    width: rem(207),
                    height: rem(200),
                    bottom: 0,
                    left: rem(123),
                    opacity: shouldReduceMotion ? 1 : 0,
                    [media["md"]]: {
                      width: rem(415),
                      height: rem(400),
                      left: rem(445),
                    },
                  }}
                >
                  {yellowCookieImage.fluid && (
                    <YellowCookieWrapper>
                      <Img fluid={yellowCookieImage.fluid} />
                    </YellowCookieWrapper>
                  )}
                </TransitionGroup>
              </Characters>
              {frontCloudImage.fluid && (
                <TransitionGroup
                  animate={
                    shouldReduceMotion
                      ? {}
                      : {
                          y: ["100%", "0%"],
                          opacity: [0, 1],
                        }
                  }
                  transition={{
                    duration: 1,
                    delay: 0.6,
                  }}
                  css={{
                    width: "100%",
                    height: rem(247),
                    left: 0,
                    bottom: 0,
                    opacity: shouldReduceMotion ? 1 : 0,
                    [media["md"]]: {
                      height: rem(485),
                    },
                  }}
                >
                  <FrontCloud fluid={frontCloudImage.fluid} />
                </TransitionGroup>
              )}
            </>
          )}
        </BackgroundImage>
      )}
    </Container>
  );
};

export default GameStorySection;

export const fragments = graphql`
  fragment GameStorySection_gameStoryBackgroundImage on ImageSharp {
    fluid(maxWidth: 1920) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_yellowCookieImage on ImageSharp {
    fluid(maxHeight: 400, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_purpleCookieImage on ImageSharp {
    fluid(maxHeight: 314, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_pinkCookieImage on ImageSharp {
    fluid(maxHeight: 260, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_whiteCookieImage on ImageSharp {
    fluid(maxHeight: 298, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_orangeCookieImage on ImageSharp {
    fluid(maxHeight: 300, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_frontCloudImage on ImageSharp {
    fluid(maxWidth: 1920, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }

  fragment GameStorySection_backCloudImage on ImageSharp {
    fluid(maxWidth: 1920, srcSetBreakpoints: [320, 640]) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }
`;
