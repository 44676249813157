import * as React from "react";
import { rem } from "polished";

import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { useTranslation } from "~/src/components/l10nContext";
import Frame from "~/src/components/noticePopup/Frame";
import Header from "~/src/components/noticePopup/Header";

type NoticePopupProps = {
  setIsNoticePopupOpen: (isOpen: boolean) => void;
};

const ContentWrapper = styled.div((props) => ({
  paddingTop: rem(30),
  paddingBottom: rem(30),
  [props.theme.media["md"]]: {
    paddingTop: rem(60),
    paddingBottom: rem(60),
  },
}));

const NoticePopup: React.FC<NoticePopupProps> = ({ setIsNoticePopupOpen }) => {
  const { t, html } = useTranslation();
  const noticeStart = new Date("2021-01-21T00:00:00+09:00");
  const noticeEnd = new Date("2021-01-21T11:00:00+09:00");
  React.useEffect(() => {
    const nowDate = new Date();
    if (noticeStart > nowDate || nowDate > noticeEnd) {
      setIsNoticePopupOpen(false);
    }
  }, [setIsNoticePopupOpen]);
  return (
    <Frame>
      <ContentWrapper>
        <Header
          title={t("Notice_Modal")}
          description={html("Notice_Modal_description")}
        />
      </ContentWrapper>
    </Frame>
  );
};

export default NoticePopup;
