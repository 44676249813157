import * as React from "react";
import { rem } from "polished";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";

type SectionTitleProps = {
  preTitle?: string;
  mainTitle: string;
  description?: string;
  className?: string;
};

const Container = styled.div((props) => ({
  textAlign: "center",
  fontSize: rem(9),
  [props.theme.media["md"]]: {
    fontSize: rem(16),
  },
}));

const Title = styled.h1({
  whiteSpace: "pre-line",
  margin: 0,
  padding: 0,
});

const Pretitle = styled.span((props) => ({
  display: "block",
  fontWeight: "normal",
  fontSize: rem(18),
  [props.theme.media["md"]]: {
    fontSize: rem(30),
  },
}));

const MainTitle = styled.strong((props) => ({
  display: "block",
  fontWeight: "bold",
  margin: `0 0 ${rem(8)} 0`,
  lineHeight: 1.1,
  fontSize: rem(35),
  [props.theme.media["md"]]: {
    fontSize: rem(60),
    margin: `0 0 ${rem(15)} 0`,
  },
}));

const Description = styled.p((props) => ({
  fontSize: rem(14),
  fontWeight: "normal",
  margin: 0,
  whiteSpace: "pre-line",
  lineHeight: 1.3,
  ":lang(ko)": {
    fontSize: rem(16),
  },
  [props.theme.media["md"]]: {
    fontSize: rem(24),
    ":lang(ko)": {
      fontSize: rem(24),
    },
  },
}));

const SectionTitle: React.FC<SectionTitleProps> = ({
  preTitle,
  mainTitle,
  description,
  className,
}) => {
  return (
    <Container className={className}>
      <Title>
        <Pretitle>{preTitle}</Pretitle>
        <MainTitle>{mainTitle}</MainTitle>
      </Title>
      {description && <Description>{description}</Description>}
    </Container>
  );
};

export default SectionTitle;
