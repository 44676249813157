import React from "react";
import { rem } from "polished";

import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";

type VideoPlayerProps = {
  iframeVideoLink: string;
};

const PlayerWrapper = styled.div({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#000000",
});

const FramePlayerWrapper = styled.div((props) => ({
  "@media (max-height: 28em)": {
    width: "calc(60vh/0.56)",
    height: "60vh",
  },
  width: "90vw",
  objectFit: "cover",
  height: "calc(90vw * 0.56)",
  borderImageSlice: 1,
  borderWidth: 0.5,
  borderStyle: "solid",
  padding: rem(2),
  borderImageSource: "linear-gradient(180deg, #FFE81E 0%, #F1C32F 100%)",
  [props.theme.media["lg"]]: {
    maxWidth: rem(1064),
    maxHeight: rem(600),
  },
}));

const PlayerFrame = styled.iframe({
  width: "100%",
  height: "100%",
  margin: 0,
  border: "none",
});

const VideoPlayer: React.FC<VideoPlayerProps> = ({ iframeVideoLink }) => {
  return (
    <PlayerWrapper>
      <FramePlayerWrapper>
        <PlayerFrame
          src={iframeVideoLink}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen={true}
          loading="eager"
        ></PlayerFrame>
      </FramePlayerWrapper>
    </PlayerWrapper>
  );
};

export default VideoPlayer;
