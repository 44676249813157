import * as React from "react";
import $ from "text2vdom";
import { rem } from "polished";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { ReactComponent as DecoBarSvg } from "~/src/components/noticePopup/header/decoBar.svg";

type HeaderProps = {
  title: string;
  description?: string;
  className?: string;
};

const Container = styled.header({
  textAlign: "center",
});

const DecoBar = styled(DecoBarSvg)((props) => ({
  width: rem(118),
  height: rem(12),
  marginBottom: rem(4),
  fill: "#e4a96a",
  stroke: "#e4a96a",
  [props.theme.media["md"]]: {
    width: rem(236),
    height: rem(24),
    marginBottom: rem(8),
  },
}));

const Text = styled.div({
  padding: `0 ${rem(28)}`,
});

const Title = styled.h1((props) => ({
  whiteSpace: "pre-line",
  fontSize: rem(22),
  color: "#FAF5D1",
  margin: 0,
  lineHeight: 1.2,
  "& b": {
    color: "#FFD143",
  },
  ":lang(ja), :lang(zh-Hant), :lang(en), :lang(fr), :lang(de)": {
    fontSize: rem(16),
  },
  [props.theme.media["md"]]: {
    fontSize: rem(40),
    ":lang(ja), :lang(zh-Hant), :lang(en), :lang(fr), :lang(de)": {
      fontSize: rem(32),
    },
  },
}));

const Description = styled.p((props) => ({
  whiteSpace: "pre-line",
  fontSize: rem(16),
  color: "#C4A893",
  margin: `${rem(4)} 0 0 0`,
  [props.theme.media["md"]]: {
    fontSize: rem(24),
    margin: `${rem(8)} 0 0 0`,
  },
}));

const Header: React.FC<HeaderProps> = ({ title, description, className }) => {
  return (
    <Container className={className}>
      <DecoBar />
      <Text>
        <Title>{title}</Title>
        {description &&
          $(description, {
            p: ({ children }) => <Description>{children}</Description>,
          })}
      </Text>
    </Container>
  );
};

export default Header;
