import type { IntrinsicElementWrapperComponent } from "@cometjs/react-utils";
import React from "react";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { rem } from "polished";
import { cx } from "@emotion/css";
import lightSideSvgUrl from "./decoratedButton/lightSide.svg";
import lightContainerSvgUrl from "./decoratedButton/lightContainer.svg";
import darkSideSvgUrl from "./decoratedButton/darkSide.svg";
import darkContainerSvgUrl from "./decoratedButton/darkContainer.svg";
import lightSideActiveSvgUrl from "./decoratedButton/lightSideActive.svg";
import lightContainerActiveSvgUrl from "./decoratedButton/lightContainerActive.svg";
import darkSideActiveSvgUrl from "./decoratedButton/darkSideActive.svg";
import darkContainerActiveSvgUrl from "./decoratedButton/darkContainerActive.svg";

type DecoratedButtonProps = {
  className?: string;
  active?: boolean;
  isSmall?: boolean;
  colorType: "light" | "dark";
};

type DecoratedButtonComponent = IntrinsicElementWrapperComponent<
  "button",
  DecoratedButtonProps
>;

const Container = styled.button((props) => ({
  width: "100%",
  padding: `0 ${rem(9)}`,
  boxSizing: "border-box",
  background: "none",
  border: "none",
  [props.theme.media["md"]]: {
    padding: `0 ${rem(14)}`,
  },
}));

const DecoratedWrapper = styled.span((props) => ({
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  border: "none",
  boxSizing: "border-box",
  whiteSpace: "nowrap",
  textDecoration: "none",
  width: "100%",
  fontSize: rem(20),
  fontWeight: "bold",
  color: "#362310",
  height: rem(42),
  [props.theme.media["md"]]: {
    height: rem(66),
  },

  // background svg
  position: "relative",
  backgroundColor: "transparent",
  backgroundImage: `url(${lightContainerSvgUrl})`,
  backgroundRepeat: "repeat no-repeat",
  backgroundSize: "auto 100%",
  "&.light": {
    backgroundImage: `url(${lightContainerSvgUrl})`,
    "::before, ::after": {
      backgroundImage: `url(${lightSideSvgUrl})`,
    },
    "&.active": {
      backgroundImage: `url(${lightContainerActiveSvgUrl})`,
      "::before, ::after": {
        backgroundImage: `url(${lightSideActiveSvgUrl})`,
      },
    },
  },
  "&.dark": {
    backgroundImage: `url(${darkContainerSvgUrl})`,
    "::before, ::after": {
      backgroundImage: `url(${darkSideSvgUrl})`,
    },
    "&.active": {
      backgroundImage: `url(${darkContainerActiveSvgUrl})`,
      "::before, ::after": {
        backgroundImage: `url(${darkSideActiveSvgUrl})`,
      },
    },
  },
  "::before, ::after": {
    content: '""',
    position: "absolute",
    top: 0,
    width: rem(9),
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right center",
    [props.theme.media["md"]]: {
      width: rem(14),
    },
  },
  "::before": {
    right: "100%",
    marginRight: rem(-1),
  },
  "::after": {
    left: "100%",
    marginLeft: rem(-1),
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
  },
}));

const ButtonText = styled.span<{ isSmall: boolean }>((props) => ({
  display: "inline-block",
  width: "100%",
  color: "#FFF3CD",
  fontWeight: 400,
  fontSize: rem(15),
  zIndex: 1,
  ':lang(en), :lang(fr), :lang(de)': {
    fontSize: props.isSmall ? rem(12) : rem(15),
  },
  letterSpacing: "-0.05em",
  ".active &": {
    fontWeight: 700,
  },
  ".light &": {
    color: "#FFF3CD",
    "&.active": {
      color: "#4B2C0C",
    },
  },
  ".dark &": {
    color: "#C18243",
    "&.active": {
      color: "#FFFFFF",
    },
  },
  [props.theme.media["md"]]: {
    fontSize: rem(21),
    ':lang(en), :lang(fr), :lang(de)': {
      fontSize: rem(21),
    },
  },
}));

const DecoratedButton: DecoratedButtonComponent = ({
  children,
  className,
  active,
  colorType,
  isSmall = false,
  ...props
}) => {
  return (
    <Container>
      <DecoratedWrapper
        className={`${cx(active && "active")} ${colorType} ${className}`}
        {...props}
      >
        <ButtonText isSmall={isSmall} className={cx(active && "active")}>
          {children}
        </ButtonText>
      </DecoratedWrapper>
    </Container>
  );
};

export default DecoratedButton;
