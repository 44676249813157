import * as React from "react";
import { rem } from "polished";
import { motion } from "framer-motion";

import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import FrameBackground from "./frame/Background";

type FrameProps = {
  className?: string;
};

const Container = styled(motion.div)((props) => ({
  position: "relative",
  width: "100%",
  minWidth: rem(320),
  margin: "0 auto",
  color: "#e4a96a",
  [props.theme.media["md"]]: {
    width: rem(480),
  },
}));

const ContentWrapper = styled.div({
  maxHeight: "100vh",
  overflowY: "auto",
  textAlign: "center",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

const Frame: React.FC<FrameProps> = ({ className, children }) => {
  return (
    <Container className={className} layoutId="event-frame">
      <ContentWrapper>
        <FrameBackground>{children}</FrameBackground>
      </ContentWrapper>
    </Container>
  );
};

export default Frame;
