import React from "react";
import Slider from "react-slick";
import { rem } from "polished";
import { keyframes, cx } from "@emotion/css";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import SectionTitle from "~/src/components/SectionTitle";
import SectionDeco from "gatsby-theme-cookierun-kingdom/src/components/SectionDeco";
import { useTranslation } from "~/src/components/l10nContext";
import DecoratedButton from "~/src/components/DecoratedButton";
import Img from "gatsby-image";
import characterHeadFrameUrl from "./characterSection/characterHeadFrame.png";
import { graphql } from "gatsby";
import arrowUrl from "./characterSection/arrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as AudioButtonImgSvg } from "./characterSection/audioButton.svg";

type CharacterSectionProps = {
  characterInformationList: readonly GatsbyTypes.CharacterSectionCharacterInformationItemFragment[];
  characterPortraitCardImage: GatsbyTypes.CharacterSection_characterPortraitCardImageFragment;
  currentLocale: string,
};

type CategoryType = "hero" | "friend" | "villain" | "legend";

type CategoryDataType = {
  buttonName: string;
  characters: readonly GatsbyTypes.CharacterSectionCharacterInformationItemFragment[];
};

type CharacterDataType = {
  hero: CategoryDataType;
  friend: CategoryDataType;
  villain: CategoryDataType;
  legend: CategoryDataType;
};

const Container = styled.div({
  backgroundColor: "#594D46",
  overflow: "hidden",
});

const SectionTitleWrapper = styled.div((props) => ({
  padding: `${rem(33)} 0 ${rem(20)} 0`,
  color: "#dbbf91",
  margin: "0 auto",
  "& .gameInfoSiteTitle h1 span": {
    fontSize: rem(18),
  },
  "& .gameInfoSiteTitle h1 strong": {
    fontSize: rem(35),
  },
  [props.theme.media["md"]]: {
    padding: `${rem(66)} 0 ${rem(40)} 0`,
    "& .gameInfoSiteTitle h1 span": {
      fontSize: rem(30),
    },
    "& .gameInfoSiteTitle h1 strong": {
      fontSize: rem(60),
    },
  },
}));

const UpdateNotice = styled.p((props) => ({
  margin: `${rem(-23)} 0 ${rem(20)} 0`,
  padding: 0,
  fontSize: rem(12),
  color: "#C4A893",
  textAlign: "center",
  ":lang(ja)": {
    letterSpacing: "-0.05em",
  },
  [props.theme.media["md"]]: {
    fontSize: rem(17),
    margin: `${rem(-40)} 0 ${rem(40)} 0`,
  },
}));

const CategoryList = styled.ul((props) => ({
  margin: "0 auto",
  marginBottom: rem(20),
  padding: `0 ${rem(30)}`,
  maxWidth: rem(450),
  display: "grid",
  gridTemplateColumns: "1fr",
  rowGap: rem(10),
  ":lang(ko), :lang(zh-Hant)": {
    gridTemplateColumns: "1fr 1fr",
    columnGap: rem(6),
    rowGap: rem(13),
  },
  [props.theme.media["md"]]: {
    marginBottom: rem(30),
    columnGap: rem(20),
    rowGap: rem(27),
    padding: 0,
    gridTemplateColumns: "1fr 1fr",
    ":lang(ko), :lang(zh-Hant)": {
      columnGap: rem(20),
      rowGap: rem(27),
      padding: 0,
    },
  },
  [props.theme.media["lg"]]: {
    maxWidth: rem(910),
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    ":lang(ko), :lang(zh-Hant)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
  },
}));

const DecoratedButtonWrapper = styled.li((props) => ({
  listStyle: "none",
  margin: 0,
  padding: 0,
  "& .smallText span": {
    ":lang(ko), :lang(zh-Hant)": {
      fontSize: rem(13),
      letterSpacing: "-0.08em",
      minHeight: rem(22),
    },
  },
  [props.theme.media["md"]]: {
    "& .smallText span": {
      ":lang(ko), :lang(zh-Hant)": {
        fontSize: rem(21),
      },
      ":lang(en), :lang(fr), :lang(de)": {
        fontSize: rem(17),
      },
      ":lang(ja)": {
        fontSize: rem(16),
        letterSpacing: "-0.08em",
      },
    },
  },
}));

const CharacterContent = styled.div((props) => ({
  margin: `0 auto`,
  position: "relative",
  "@media (min-width: 48em)": {
    display: "grid",
    width: rem(768),
    gridTemplateColumns: `${rem(140)} 1fr`,
  },
  paddingBottom: rem(100),
  [props.theme.media["lg"]]: {
    width: rem(1137),
  },
}));

const CharacterList = styled.ul((props) => ({
  listStyle: "none",
  margin: `0 0 0 ${rem(28)}`,
  padding: 0,
  position: "relative",
  zIndex: 1,
  display: "none",
  "@media (min-width: 48em)": {
    display: "block",
  },
  [props.theme.media["lg"]]: {
    margin: 0,
  },
}));

const CharacterInfomationContainer = styled.div((props) => ({
  "@media (min-width: 48em)": {
    width: rem(1117),
    transform: "translateX(-117px)",
  },
}));

const CharacterItem = styled.li({
  listStyle: "none",
  margin: `${rem(20)} 0`,
  padding: 0,
  opacity: 0.5,
  transition: "all .2s ease",
  "&:first-of-type": {
    margin: 0,
  },
  "&.active": {
    opacity: 1,
  },
});

const CharacterButton = styled.button({
  backgroundImage: `url(${characterHeadFrameUrl})`,
  backgroundSize: "contain",
  width: rem(139),
  height: rem(134),
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  margin: 0,
  padding: 0,
  position: "relative",
});

const CharacterHeadContainer = styled.div({
  width: rem(135),
  height: rem(135),
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
});

const CharacterInformation = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: `1fr`,
  position: "relative",
  pointerEvents: "none",
  "&.active": {
    pointerEvents: "initial",
  },
  [props.theme.media["lg"]]: {
    gridTemplateColumns: `1fr ${rem(475)}`,
  },
}));

const CharacterPortrait = styled.div((props) => ({
  marginLeft: rem(47),
  position: "relative",
  height: `calc(411px + 249 * ((100vw - 320px) / 150))`,
  "@media (min-width: 29.3em)": {
    height: rem(660),
  },
  [props.theme.media["md"]]: {
    height: rem(755),
  },
}));

const CharacterInfoText = styled.div((props) => ({
  margin: `0 auto`,
  padding: `0 ${rem(30)}`,
  position: "relative",
  maxWidth: rem(400),
  marginTop: rem(20),
  [props.theme.media["md"]]: {
    marginTop: rem(32),
  },
  "@media (min-width: 48em)": {
    maxWidth: "initial",
    padding: 0,
    margin: 0,
    marginTop: rem(32),
    marginLeft: rem(190),
  },
  [props.theme.media["lg"]]: {
    marginLeft: rem(128),
  },
}));

const CharacterPortraitCardContainer = styled.div((props) => ({
  maxWidth: rem(475),
  maxHeight: rem(755),
  padding: `0 ${rem(30)}`,
  boxSizing: "border-box",
  position: "absolute",
  left: "50%",
  top: 0,
  width: "100vw",
  transform: "translateX(-50%)",
  "@media (min-width: 29.3em)": {
    maxWidth: "initial",
    width: rem(475),
  },
  [props.theme.media["md"]]: {
    padding: 0,
  },
  "@media (min-width: 48em)": {
    width: rem(475),
    left: rem(170),
    top: 0,
    transform: "initial",
  },
  [props.theme.media["lg"]]: {
    left: rem(187),
  },
}));

const moving = keyframes`
  0% {
    transform: translateY(1%);
  }

  50% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(1%);
  }
`;

const CharacterPortraitImgContainer = styled.div((props) => ({
  pointerEvents: "none",
  position: "absolute",
  width: `calc(550px + 250 * ((100vw - 320px) / 148))`,
  left: "50%",
  top: `calc(-10px + 15 * ((100vw - 320px) / 148))`,
  transform: "translateX(-40%)",
  "@media (min-width: 29.52em)": {
    width: rem(800),
    top: rem(5),
  },
  [props.theme.media["md"]]: {
    width: rem(900),
    maxWidth: "none",
    left: "50%",
    top: rem(-15),
    transform: "translateX(-40%)",
  },
  "@media (min-width: 48em)": {
    display: "block",
    position: "absolute",
    left: rem(10),
    top: rem(15),
    transform: "initial",
  },
  [props.theme.media["lg"]]: {
    left: rem(25),
  },
}));

const CharacterCategory = styled.p((props) => ({
  fontSize: rem(15),
  color: "#FFF3CD",
  margin: 0,
  padding: 0,
  [props.theme.media["md"]]: {
    fontSize: rem(26),
  },
}));

const CharacterName = styled.h2((props) => ({
  fontSize: rem(22),
  color: "#FFF3CD",
  padding: 0,
  margin: `0 0 ${rem(5)} 0`,
  lineHeight: 1.2,
  maxWidth: "80%",
  whiteSpace: "pre-line",
  [props.theme.media["md"]]: {
    fontSize: rem(50),
    margin: `0 0 ${rem(10)} 0`,
    maxWidth: rem(350),
    ":lang(en), :lang(fr), :lang(de)": {
      maxWidth: rem(340),
    },
    ":lang(ja), :lang(en), :lang(fr), :lang(th)": {
      fontSize: rem(42),
    },
    ":lang(de)": {
      fontSize: rem(36),
    },
  },
}));

const CharacterVoiceWrapper = styled.div((props) => ({
  fontSize: rem(15),
  color: "#FFF3CD",
  margin: 0,
  padding: 0,
  display: "flex",
  alignItems: "center",
  [props.theme.media["md"]]: {
    fontSize: rem(26),
    ':lang(en), :lang(fr), :lang(de)': {
      fontSize: rem(24),
    },
  },
  [props.theme.media["lg"]]: {
    display: 'block',
  },
}));

const CharacterVoiceText = styled.span((props) => ({
  height: rem(22),
  lineHeight: rem(22),
  [props.theme.media["md"]]: {
    height: rem(40),
    lineHeight: rem(40),
  },
}));

const CharacterDescription = styled.p((props) => ({
  fontSize: rem(15),
  color: "#C4A893",
  margin: `${rem(12)} 0 0 0`,
  padding: 0,
  whiteSpace: "pre-line",
  [props.theme.media["md"]]: {
    fontSize: rem(22),
    ":lang(ja), :lang(en), :lang(fr), :lang(de), :lang(th)": {
      fontSize: rem(18),
    },
    margin: `${rem(20)} 0 0 0`,
  },
  "@media (min-width: 48em)": {
    width: rem(468),
  },
  textShadow: `#594D46 ${rem(3)} ${rem(3)} ${rem(3)}`,
  [props.theme.media["lg"]]: {
    width: rem(300),
  },
}));

const ArrowsContainer = styled.span((props) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  transform: "rotate(270deg)",
  top: `calc(435px + 245 * ((100vw - 320px) / 150))`,
  right: rem(35),
  "@media (min-width: 29.3em)": {
    right: "50%",
    transform: "rotate(270deg) translateY(290%)",
    top: rem(680),
  },
  [props.theme.media["md"]]: {
    top: rem(825),
    ":lang(en), :lang(fr), :lang(de), :lang(ja), :lang(zh-Hant)": {
      top: rem(815),
    },
    right: "50%",
    transform: "rotate(270deg) translateY(290%)",
  },
  "@media (min-width: 48em)": {
    transform: "none",
    top: rem(825),
    ":lang(en), :lang(fr), :lang(de), :lang(ja), :lang(zh-Hant)": {
      top: rem(815),
    },
    right: "initial",
    left: rem(580),
  },
  [props.theme.media["lg"]]: {
    top: "initial",
    ":lang(en), :lang(fr), :lang(de), :lang(ja), :lang(zh-Hant)": {
      top: "initial",
    },
    bottom: rem(15),
    left: rem(750),
  },
}));

const ArrowButton = styled.button({
  cursor: "pointer",
  background: "none",
  border: "none",
  "&:nth-of-type(2)": {
    transform: "rotate(180deg)",
  },
});

const Arrow = styled.img((props) => ({
  width: rem(35),
  [props.theme.media["md"]]: {
    width: rem(45),
  },
}));

const AudioButton = styled.button((props) => ({
  background: "none",
  border: "none",
  position: "relative",
  zIndex: 1,
  cursor: "pointer",
  width: rem(22),
  height: rem(22),
  padding: 0,
  margin: 0,
  marginLeft: rem(5),
  [props.theme.media["md"]]: {
    width: rem(40),
    height: rem(40),
  },
  [props.theme.media["lg"]]: {
    display: 'block',
    marginTop: rem(10),
    marginLeft: 0,
  },
}));

const CharacterSection: React.FC<CharacterSectionProps> = ({
  characterInformationList,
  characterPortraitCardImage,
  currentLocale,
}) => {
  const { t } = useTranslation();
  const [selectedCategory, selectCategory] = React.useState<CategoryType>(
    "hero"
  );
  const [selectedCharacterNumber, selectCharacterNumber] = React.useState(1);
  const [voice, setVoice] = React.useState<String>("hero/pure-vanilla-cookie");
  const audio = React.useRef<HTMLAudioElement | null>();
  const slider = React.useRef<Slider | null>(null);

  const characterData = React.useMemo(() => {
    const temporaryCharacterData: CharacterDataType = {
      hero: {
        buttonName: t("GameIntroduction_Characters_hero"),
        characters: [],
      },
      friend: {
        buttonName: t("GameIntroduction_Characters_friend"),
        characters: [],
      },
      legend: {
        buttonName: t("GameIntroduction_Characters_legend"),
        characters: [],
      },
      villain: {
        buttonName: t("GameIntroduction_Characters_villain"),
        characters: [],
      },
    };
    characterInformationList.forEach((character, i) => {
      const category = character.category as CategoryType;
      const prevCharacters = temporaryCharacterData[category].characters;
      const newCharacters = [...prevCharacters, character];
      temporaryCharacterData[category] = {
        ...temporaryCharacterData[category],
        characters: newCharacters,
      };
    });
    return temporaryCharacterData;
  }, [characterInformationList]);

  const changeCategory = (category: CategoryType) => () => {
    selectCategory(category);
    selectCharacterNumber(1);
    setVoice(characterData[category].characters[0].filePath);
    slider.current && slider.current.slickGoTo(0);
  };

  const onClickCharacter = (i: number) => () => {
    selectCharacterNumber(i + 1);
    slider.current && slider.current.slickGoTo(i);
  };

  const onClickLeftArrow = (current: number) => () => {
    slider.current && slider.current.slickGoTo(current - 2);
  };

  const onClickRightArrow = (current: number) => () => {
    slider.current && slider.current.slickGoTo(current);
  };

  const onClickPlayVoice = () => {
    audio.current && audio.current.pause();
    audio.current = new Audio(`../../voice/${currentLocale}/${voice}.wav`);
    audio.current.play();
  };

  const handleAfterChange = (current: number) => {
    selectCharacterNumber(current + 1);
    setVoice(characterData[selectedCategory].characters[current].filePath);
  };

  return (
    <Container>
      <SectionDeco decoColor="#C08D55" />
      <SectionTitleWrapper>
        <SectionTitle
          mainTitle={t("GameIntroduction_Characters_title")}
          preTitle={t("IndexPage_Section_subtitle")}
        />
      </SectionTitleWrapper>
      <UpdateNotice>{t("GameIntroduction_Character_Notice")}</UpdateNotice>
      <CategoryList>
        {Object.keys(characterData).map((category, i) => {
          return (
            <DecoratedButtonWrapper key={category}>
              <DecoratedButton
                colorType="light"
                onClick={changeCategory(category as keyof CharacterDataType)}
                active={selectedCategory === category}
                className={i === 1 ? "smallText" : ""}
              >
                {characterData[category as keyof CharacterDataType].buttonName}
              </DecoratedButton>
            </DecoratedButtonWrapper>
          );
        })}
      </CategoryList>
      <CharacterContent>
        <CharacterList>
          {characterData[selectedCategory].characters.map((v, i) => {
            return (
              <CharacterItem
                className={i === selectedCharacterNumber - 1 ? "active" : ""}
                onClick={onClickCharacter(i)}
                key={selectedCategory + i}
              >
                <CharacterButton>
                  <CharacterHeadContainer>
                    {v.headImage.fluid && (
                      <Img fluid={v.headImage.fluid} loading="auto" />
                    )}
                  </CharacterHeadContainer>
                </CharacterButton>
              </CharacterItem>
            );
          })}
        </CharacterList>
        <CharacterInfomationContainer>
          <CharacterPortraitCardContainer>
            {characterPortraitCardImage.fluid && (
              <Img fluid={characterPortraitCardImage.fluid} loading="auto" />
            )}
          </CharacterPortraitCardContainer>
          <ArrowsContainer>
            <ArrowButton onClick={onClickLeftArrow(selectedCharacterNumber)}>
              <Arrow src={arrowUrl} />
            </ArrowButton>
            <ArrowButton onClick={onClickRightArrow(selectedCharacterNumber)}>
              <Arrow src={arrowUrl} />
            </ArrowButton>
          </ArrowsContainer>
          <Slider
            ref={slider}
            dots={false}
            fade={true}
            infinite={true}
            speed={300}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            afterChange={handleAfterChange}
          >
            {characterData[selectedCategory].characters.map((character, i) => {
              return (
                <div css={{ outline: "none" }} key={selectedCategory + i}>
                  <CharacterInformation
                    className={cx(
                      i === selectedCharacterNumber - 1 && "active"
                    )}
                  >
                    <CharacterPortrait>
                      <CharacterPortraitImgContainer>
                        {character.portraitImage.fluid && (
                          <Img
                            fluid={character.portraitImage.fluid}
                            loading="auto"
                            css={{
                              animation: `${moving} 2.5s ease-in-out infinite`,
                            }}
                          />
                        )}
                      </CharacterPortraitImgContainer>
                    </CharacterPortrait>
                    <CharacterInfoText>
                      <CharacterCategory>
                        {characterData[selectedCategory].buttonName}
                      </CharacterCategory>
                      <CharacterName>{character.name}</CharacterName>
                      {character.cv && (
                        <CharacterVoiceWrapper>
                          <CharacterVoiceText>
                            {t("GameIntroduction_Characters_voiceActorText")}. {character.cv}
                          </CharacterVoiceText>
                          <AudioButton onClick={onClickPlayVoice}>
                            <AudioButtonImgSvg />
                          </AudioButton>
                        </CharacterVoiceWrapper>
                      )}
                      <CharacterDescription>
                        {character.description}
                      </CharacterDescription>
                    </CharacterInfoText>
                  </CharacterInformation>
                </div>
              );
            })}
          </Slider>
        </CharacterInfomationContainer>
      </CharacterContent>
    </Container>
  );
};

export default CharacterSection;

export const fragments = graphql`
  fragment CharacterSectionCharacterInformationItem on CharacterInformationItem {
    cv
    name
    category
    description
    filePath
    headImage {
      fluid(maxWidth: 110, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    portraitImage {
      fluid(maxWidth: 900, srcSetBreakpoints: [320, 640, 1200], quality: 85) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment CharacterSection_characterPortraitCardImage on ImageSharp {
    fluid(maxWidth: 475, srcSetBreakpoints: [320, 640, 1200], quality: 85) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }
`;
