import * as React from "react";
import { rem, em, darken } from "polished";
import { motion } from "framer-motion";

import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { ReactComponent as CloseButtonSvg } from "./closeButton/closeButton.svg";
import { ReactComponent as CloseButtonPolygonSvg } from "./closeButton/closeButtonPolygon.svg";

type CloseButtonProps = {
  onClick?: () => void;
  className?: string;
};

const CloseButtonWrapper = styled(motion.div)((props) => ({
  position: "absolute",
  fontSize: rem(12),
  top: em(3),
  right: em(3),
  zIndex: 1,
  [props.theme.media["md"]]: {
    fontSize: rem(16),
    top: em(24),
    right: em(24),
  },
  "&.video-player": {
    top: 0,
    right: 0,
    transform: "translateY(-100%)",
  },
}));

const ButtonPolygon = styled(CloseButtonPolygonSvg)((props) => ({
  width: rem(38),
  height: rem(38),
  [props.theme.media["md"]]: {
    width: rem(60),
    height: rem(60),
  },
}));

const Button = styled.button((props) => ({
  position: "relative",
  display: "inline-block",
  cursor: "pointer",
  width: rem(38),
  height: rem(38),
  background: "none",
  border: "none",
  padding: 0,
  [props.theme.media["md"]]: {
    width: rem(60),
    height: rem(60),
  },
}));

const ButtonIcon = styled(CloseButtonSvg)({
  position: "absolute",
  top: 0,
  right: 0,
  width: "50%",
  height: "50%",
  transform: "translateX(-50%) translateY(40%)",
});

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, className }) => {
  return (
    <CloseButtonWrapper className={className}>
      <Button onClick={onClick}>
        <ButtonPolygon />
        <ButtonIcon />
      </Button>
    </CloseButtonWrapper>
  );
};

export default CloseButton;
