import * as React from "react";
import { rem } from "polished";
import { cx } from "@emotion/css";
import { graphql } from "gatsby";
import SectionDeco from "gatsby-theme-cookierun-kingdom/src/components/SectionDeco";
import SectionTitle from "~/src/components/SectionTitle";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { carouselContext } from "gatsby-theme-cookierun-kingdom/src/carouselContext";
import Img from "gatsby-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DecoratedButton from "~/src/components/DecoratedButton";
import { useTranslation } from "~/src/components/l10nContext";

import arrowImgUrl from "./gameContents/arrow.png";
import frameImgUrl from "./gameContents/frame.png";

type GameContentsProps = {
  gameInformationList: readonly GatsbyTypes.GameContentsGameInformationItemFragment[];
};

const Container = styled.div((props) => ({
  backgroundColor: "#302A24",
  width: "100%",
  textAlign: "center",
  height: "auto",
  paddingBottom: rem(34),
  [props.theme.media["md"]]: {
    paddingBottom: rem(58),
  },
}));

const SectionTitleWrapper = styled.div((props) => ({
  color: "#C59771",
  padding: `${rem(33)} 0 ${rem(15)} 0`,
  [props.theme.media["md"]]: {
    padding: `${rem(66)} 0 ${rem(30)} 0`,
  },
}));

const ButtonContainer = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  width: "100%",
  margin: "0 auto",
  [props.theme.media["sm"]]: {
    padding: 0,
    maxWidth: rem(520),
  },
  [props.theme.media["lg"]]: {
    width: rem(906),
    maxWidth: rem(906),
  },
}));

const DecoratedButtonWrapper = styled.div((props) => ({
  minWidth: rem(80),
  maxWidth: rem(136),
  width: "28%",
  margin: `${rem(4)} ${rem(8)}`,
  [props.theme.media["md"]]: {
    margin: `${rem(6)} ${rem(12)}`,
  },
  [props.theme.media["lg"]]: {
    margin: 0,
    marginRight: rem(24),
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const SliderContainer = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: rem(32),
  padding: `0 ${rem(12)}`,
  '@media (min-width: 48em)': {
    padding: `0 ${rem(15)}`,
    marginTop: rem(48),
  },
  [props.theme.media["lg"]]: {
    padding: 0,
    marginTop: rem(54),
  },
}));

const FrameImage = styled.img((props) => ({
  position: "absolute",
  top: rem(-10),
  left: rem(-10),
  width: `calc(100% + ${rem(20)})`,
  height: `calc(100% + ${rem(12)})`,
  pointerEvents: "none",
  '@media (min-width: 30em)': {
    top: rem(-15),
    left: rem(-14),
    width: `calc(100% + ${rem(28)})`,
    height: `calc(100% + ${rem(26)})`,
  },
  '@media (min-width: 48em)': {
    top: rem(-26),
    left: rem(-23),
    width: `calc(100% + ${rem(46)})`,
    height: `calc(100% + ${rem(47)})`,
  },
}));

const SliderWrapper = styled.div({
  width: `calc(100% - ${rem(40)})`,
  height: "auto",
  position: "relative",
  '@media (min-width: 48em)': {
    maxWidth: rem(767),
    maxHeight: rem(498),
  },
});

const SliderImageWrapper = styled.div({
  width: "100%",
  height: "100%",
});

const SliderButton = styled.button((props) => ({
  border: "none",
  background: `url(${arrowImgUrl})`,
  backgroundSize: "100% 100%",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  display: "none",
  cursor: "pointer",
  '@media (min-width: 59em)': {
    display: "block",
    padding: 0,
    width: rem(42),
    height: rem(65),
  },
  [props.theme.media["lg"]]: {
    width: rem(52),
    height: rem(80),
  },
}));

const LeftSliderButton = styled(SliderButton)((props) => ({
  marginRight: rem(34),
  [props.theme.media["lg"]]: {
    marginRight: rem(56),
  },
}));

const RightSliderButton = styled(SliderButton)((props) => ({
  transform: "matrix(-1, 0, 0, -1, 0, 0)",
  marginLeft: rem(34),
  [props.theme.media["lg"]]: {
    marginLeft: rem(56),
  },
}));

const IndicatorContainer = styled.ul((props) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  marginTop: rem(16),
  listStyle: "none",
  fontSize: 0,
  lineHeight: 0,
  '@media (min-width: 30em)': {
    marginTop: rem(36),
  },
  '@media (min-width: 48em)': {
    marginTop: rem(45),
  },
}));

const Indicator = styled.li({
  display: "inline-block",
  borderRadius: rem(5),
  backgroundColor: "#C08D55",
  transition: "width 0.2s ease-in-out",
  width: 6,
  height: 6,
  marginRight: rem(10),
  cursor: "pointer",
  "&.active": {
    cursor: "default",
    pointerEvents: "none",
    width: 38,
    height: 6,
    backgroundColor: "#E2CA99",
    marginRight: rem(12),
  },
});

const GameContents: React.FC<GameContentsProps> = ({ gameInformationList }) => {
  const { playCarousel, setPlayCarousel } = React.useContext(carouselContext);
  const { t } = useTranslation();
  const [isClickNavigator, setIsClickNavigator] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slider = React.useRef<Slider | null>(null);
  const imageLength = React.useMemo(() => gameInformationList.length, [
    gameInformationList,
  ]);
  const onClickLeftButton = () => {
    slider.current && slider.current.slickPrev();
  };
  const onClickRightButton = () => {
    slider.current && slider.current.slickNext();
  };
  const handleSlideDirectly = (buttonIndex: number) => {
    setCurrentSlide(buttonIndex);
    setIsClickNavigator(true);
    setPlayCarousel(false);
    slider.current && slider.current.slickGoTo(buttonIndex);
  };
  React.useEffect(() => {
    if (playCarousel && !isClickNavigator) {
      slider.current?.slickPlay();
    }
  }, [playCarousel, isClickNavigator]);
  return (
    <Container>
      <SectionDeco decoColor={"#C08D55"} />
      <SectionTitleWrapper>
        <SectionTitle
          mainTitle={t("GameIntroduction_Contents_title")}
          preTitle={t("IndexPage_Section_subtitle")}
        />
      </SectionTitleWrapper>
      {gameInformationList && (
        <>
          <ButtonContainer>
            {gameInformationList.map((gameInformation, idx) => (
              <DecoratedButtonWrapper key={`${gameInformation.title}-button`}>
                <DecoratedButton
                  colorType="dark"
                  active={currentSlide === idx}
                  isSmall={true}
                  onClick={() => handleSlideDirectly(idx)}
                >
                  {gameInformation.title}
                </DecoratedButton>
              </DecoratedButtonWrapper>
            ))}
          </ButtonContainer>
          <SliderContainer>
            <LeftSliderButton onClick={onClickLeftButton} />
            <SliderWrapper>
              <Slider
                ref={slider}
                dots={false}
                infinite={true}
                speed={300}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                autoplay={playCarousel}
                afterChange={setCurrentSlide}
              >
                {gameInformationList.map((gameInformation) => {
                  return (
                    <div key={`${gameInformation.title}-image`}>
                      <SliderImageWrapper>
                        {gameInformation.image.fluid && (
                          <Img
                            fluid={gameInformation.image.fluid}
                            style={{ width: "100%", height: 'auto', }}
                            imgStyle={{ objectFit: "contain" }}
                            loading="auto"
                          />
                        )}
                      </SliderImageWrapper>
                    </div>
                  );
                })}
              </Slider>
              <FrameImage src={frameImgUrl} />
            </SliderWrapper>
            <RightSliderButton onClick={onClickRightButton} />
          </SliderContainer>
        </>
      )}
      <IndicatorContainer>
        {[...Array(imageLength).keys()].map((value) => (
          <Indicator
            key={`indicator-${value}`}
            onClick={() => handleSlideDirectly(value)}
            className={cx(value === currentSlide && "active")}
          />
        ))}
      </IndicatorContainer>
    </Container>
  );
};

export default GameContents;

export const query = graphql`
  fragment GameContentsGameInformationItem on GameInformationItem {
    title
    image {
      fluid(maxWidth: 900, srcSetBreakpoints: [320, 640], quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
