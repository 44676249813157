import * as React from "react";
import { rem } from "polished";

import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";
import { ReactComponent as TopDecoSvg } from "./background/topDeco.svg";
import { ReactComponent as CornerDecoSvg } from "./background/cornerDeco.svg";

const Container = styled.div((props) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  fontSize: rem(12),
  boxSizing: "border-box",
  strokeWidth: 1,
  backgroundColor: "#462503",
  [props.theme.media["md"]]: {
    fontSize: rem(16),
  },
}));

const CornerDeco = styled(CornerDecoSvg)((props) => ({
  position: "absolute",
  width: rem(38),
  height: rem(34),
  pointerEvents: "none",
  fill: "#462503",
  stroke: "#83592C",
  strokeWidth: rem(2),
  "& path:nth-of-type(3)": {
    fill: "#83592C",
  },
  "&.topLeft": {
    top: rem(1),
    left: rem(1),
  },
  "&.topRight": {
    top: rem(3),
    right: rem(-1),
    transform: "rotate(90deg)",
  },
  "&.bottomLeft": {
    bottom: rem(1),
    left: rem(1),
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  "&.bottomRight": {
    bottom: rem(1),
    right: rem(1),
    transform: "rotate(-180deg)",
  },
  [props.theme.media["md"]]: {
    width: rem(62),
    height: rem(57),
    strokeWidth: rem(1),
    "&.topLeft": {
      top: rem(1),
      left: rem(1),
    },
    "&.topRight": {
      top: rem(3),
      right: rem(-2),
    },
    "&.bottomLeft": {
      bottom: rem(1),
      left: rem(1),
    },
    "&.bottomRight": {
      bottom: rem(1),
      right: rem(1),
    },
  },
}));

const TopDeco = styled(TopDecoSvg)((props) => ({
  position: "absolute",
  width: rem(20),
  height: rem(68),
  pointerEvents: "none",
  fill: "#83592C",
  top: rem(8),
  left: rem(20),
  [props.theme.media["md"]]: {
    width: rem(32),
    height: rem(108),
    top: rem(15),
    left: rem(31),
  },
}));

const Contents = styled.div((props) => ({
  position: "relative",
  "::before": {
    position: "absolute",
    display: "block",
    content: '" "',
    left: rem(4),
    right: rem(4),
    top: rem(4),
    bottom: rem(4),
    pointerEvents: "none",
    border: "1px solid #83592C",
  },
  "::after": {
    position: "absolute",
    display: "block",
    content: '" "',
    left: rem(8),
    right: rem(8),
    top: rem(8),
    bottom: rem(8),
    pointerEvents: "none",
    border: "1px solid #83592C",
  },
  [props.theme.media["md"]]: {
    "::before": {
      left: rem(5),
      right: rem(5),
      top: rem(5),
      bottom: rem(5),
    },
    "::after": {
      left: rem(14),
      right: rem(14),
      top: rem(12),
      bottom: rem(12),
    },
  },
}));

const EventBackground: React.FC = ({ children }) => {
  return (
    <Container>
      <Contents>
        <CornerDeco className="topLeft" />
        <CornerDeco className="topRight" />
        <CornerDeco className="bottomLeft" />
        <CornerDeco className="bottomRight" />
        <TopDeco className="left" />
        {children}
      </Contents>
    </Container>
  );
};

export default EventBackground;
