import React from "react";

import type { Media } from "~/src/media";
import { styled } from "gatsby-theme-cookierun-kingdom/src/components/themeContext";

import backgroundImgUrl from "./keyVisualBackground/keyVisualBackground.png";
import backgroundImgMobileUrl from "./keyVisualBackground/keyVisualBackgroundMobile.png";

type KeyVisualBackgroundProps = {
  mobileSourceSetList: Media[];
  desktopSourceSetList: Media[];
};

const Container = styled.div({
  position: "relative",
  width: "100%",
  height: "100%",
  background: "#000000",
});

const BackgroundVideo = styled.video((props) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  opacity: 0.5,
  objectFit: "cover",
  display: "none",
  [props.theme.media["md"]]: {
    display: "block",
  },
}));

const MobileBackgroundVideo = styled(BackgroundVideo)((props) => ({
  display: "block",
  [props.theme.media["md"]]: {
    display: "none",
  },
}));

const KeyVisualBackground: React.FC<KeyVisualBackgroundProps> = ({
  mobileSourceSetList,
  desktopSourceSetList,
  children,
}) => {
  return (
    <Container>
      <MobileBackgroundVideo
        loop
        muted
        autoPlay
        playsInline
        controls={false}
        poster={backgroundImgMobileUrl}
      >
        {mobileSourceSetList.map((sourceSet) => (
          <source
            key={sourceSet.src + sourceSet.type}
            src={sourceSet.src}
            type={sourceSet.type}
          />
        ))}
      </MobileBackgroundVideo>
      <BackgroundVideo
        loop
        muted
        autoPlay
        playsInline
        controls={false}
        poster={backgroundImgUrl}
      >
        {desktopSourceSetList.map((sourceSet) => (
          <source
            key={sourceSet.src + sourceSet.type}
            src={sourceSet.src}
            type={sourceSet.type}
          />
        ))}
      </BackgroundVideo>
      {children}
    </Container>
  );
};

export default KeyVisualBackground;
